.public-page-main-section {
    font-family: 'Geist', sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 7.5rem 2.5rem;
    margin-bottom: 8.75rem;
    color: var(--grey-300);
    margin-right: 10.313rem;

}

.sp-content {
    width: 60%;
}

.sp-title {
    font-weight: 400;
    font-size: 3.75rem;
    line-height: 66px;
    text-align: start;
    width: 90%;
}

.sp-text {
    font-size: 1.5rem;
    font-weight: 400;
    text-align: left;
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
    width: 85%;
}

@media (max-width: 768px) {
    .public-page-main-section {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0;
        margin-bottom: 0.5rem;
        margin-right: 0;
    }

    .sp-content {
        width: 100%;
        padding: 1.5rem;
    }

    .sp-title {
        font-weight: 400;
        font-size: 45px;
        text-align: start;
        width: 100%;
        font-size: 30px;
        font-weight: 400;
        line-height: 36px;
        margin-top: 16px;
    }

    .sp-text {
        font-size: 1rem;
        font-weight: 400;
        text-align: left;
        margin-top: 2rem;
        margin-bottom: 2rem;
        width: 85%;

  


    }

    .image-container svg {
        width: 310px;
        height: 310px;
    }

}