.challenges-section {
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding: 6.25rem 3.75rem;
    padding-right: 9.625rem;
    color: var(--grey-300);
    font-family: 'Geist', sans-serif;
    gap: 18.75rem;
}

.challenges-content {
    flex: 1 1 45%;
}

.challenges-title {
    font-size: 1.25rem;
    margin-bottom: 3.75rem;
    font-weight: 400;

}

.challenges-text {
    font-size: 1.5rem;
    font-weight: 400px;
}

.challenges-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
}

.challenge-item {
    display: flex;
    gap: 1.25rem;
    align-items: center;
    width: 100%;
}

.challenge-number {
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    font-weight: 600;
    background-color: var(--grey-600);
    color: var(--navy-600);
    border-radius: 50%;
}

.challenge-description {
    width: 29.813rem;
    height: 30%;
    padding: 1.25rem;
    background-color: var(--navy-500);
    gap: 1rem;
    border-radius: 0.625rem;
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: var(--grey-300);
}

@media (max-width: 1476px) {
    .challenges-section {
        gap: 2.75rem;
    }
    
  }
  

@media (max-width: 768px) {
    .challenges-section {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10rem 1.5rem;
        margin-bottom: 3.75rem;
        padding: 0;
        gap: 2.5rem;
    }

    .challenges-content{
        flex: 1 1 100%;
    }

    .challenges-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }

    .challenge-item {
        flex-direction: column;
        align-items: flex-start;
        margin: 0;
    }


    .challenge-description {
        width: 100%;
    }

    .challenges-title {
        font-size: 1.25rem;
        font-weight: 400;
        margin-bottom: 2.5rem;
    }
}