.p404-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    color: white;
 
}

h1 {
    margin-bottom: 20px;
}
.not-found-text{
    color: #fff !important;
}