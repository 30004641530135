.public-page-benefits-page {
    display: flex;
    flex-direction: column;
    margin-top: 0;
}

.public-page-benefits-section {
    width: 50%;
    background-image: url(../../assets/svg/background.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    margin-bottom: 2.5rem;
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: var(--grey-300);
    font-family: 'Geist', sans-serif;
    border-radius: 0.625rem;
}

.plus-icon {
    margin-bottom: 1rem;
}

.benefits-title {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 31.2px;
    text-align: left;
    color: var(--blue-100);
    margin-bottom: 3rem;
}

.benefits-text {
    font-size: 1.25rem;
    font-weight: 500;
    text-align: left;
    margin-bottom: 0.25rem;
}

.benefits-text:not(:first-child) {
    margin-top: 1.5rem;
}

.benefits-signup-section {
    padding: 0 2.5rem;
    margin-bottom: 8.75rem;
}

.benefits-signup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 1.25rem;
    padding: 2rem 3.75rem;
    background-color: var(--grey-800);
}

.benefits-signup-title {
    font-size: 1.375rem;
    color: var(--blue-300);
    font-weight: 400;
    line-height: 28.6px;
    text-align: center;
}

.benefits-subtext {
    font-size: 1.125rem;
    color: var(--blue-300);
    margin-bottom: 1.5rem;
}

.large-button {
    width: 9%;
}

@media (max-width: 768px) {

    .public-page-benefits-page {
        justify-content: center;
        align-items: center;
    }
    .benefits-signup  {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 2.5rem;
        padding: 1.5rem;

    }

    .benefits-signup-title {
        text-align: left;
    }

    .public-page-benefits-section {
        width: 90%;
        margin: 0;
        padding: 1rem;
        display: flex;
        border-radius: 0.625rem;
    }

    .benefits-signup-section {
        width: 98%;
        margin: 0;
        padding: 1rem;
        margin-bottom: 3rem;
        align-items: center;
        justify-content: center;
    }

    .large-button {
        width: 30%;
    }
    

}