.roles-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 6.25rem 0;
    gap: 6.25rem;
    color: var(--grey-300);
}

.roles-small-title {
    font-weight: 400;
    font-size: 18px;
    line-height: 23.4px;
    text-align: start;
}

.roles-content {
   margin: 0 2.5rem; 
}

@media (max-width: 768px) {

    .roles-section {
        gap: 3.75rem;
    }

    .roles-content {
        margin: 0 1.5rem; 
     }
}