.how-it-works-section {
    margin: 140px 40px;
    background-image: url(../../assets/svg/background.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 24px;
    color: var(--grey-300);
}


.how-it-works-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 60px;
    padding: 60px 40px;
    margin-left: 40px;
    margin-right: 96px;
}

.how-it-works-text-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 100px;
}

.how-it-works-title {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 23.4px;
}

.how-it-works-text {
    font-size: 20px;
    font-weight: 500;
    line-height: 66px;
    text-align: left;
}

.how-it-works-line {
    border-top: 1px solid var(--blue-300);
    height: 2px;
}



@media (max-width: 1446px) {
    .how-it-works-content {
        
        margin-right: 10px;
    }
}
@media (max-width: 768px) {

    .how-it-works-section {
        margin: 60px 24px;
    }
    

    .how-it-works-content {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 78px;
        padding: 20px 16px;
        margin: 0;
    }

    .how-it-works-title {
        font-size: 14px;
        font-weight: 400;
        line-height: 18.2px; 
    }

    
    .how-it-works-text {
        font-size: 18px;
        font-weight: 500;
        line-height: 66px;
        width: 100%;
    }

    .how-it-works-image {
        width: 100%;
    }

    .how-it-works-image svg {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .how-it-works-text-section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        gap: 78px;
    }
}
