.about-who-we-are {
    font-family: 'Geist', sans-serif;
    max-width: 100%;
    background-image: url(../../assets/svg/background.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 6.25rem 2.5rem;
    color: var(--grey-300);
}


.about-who-we-are-content {
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding: 0 2.5rem;
    gap: 6.813rem;
}

.who-we-are-title {
    color: var(--blue-100);
    font-size: 1.375rem;
    margin-bottom: 2.5rem;
}

.who-we-are-text {
    font-size: 1.5rem;
    width: 75%;
}

.about-who-we-are-svg {
    width: 100%;
    height: 100%;
}

@media (max-width: 1766px) {
    .about-who-we-are {
        padding: 5.25rem 1.5rem;
    }

    .who-we-are-text {
        font-size: 1.25rem;
        width: 100%;
    }

    .about-who-we-are-content{
        padding: 0 1.25rem;
    }
    
  }

@media (max-width: 768px) {

    .about-who-we-are {
        padding: 0;
        margin: 1.5rem;
        border-radius: 1.5rem;

    }
    
    .about-who-we-are-content {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1.25rem;
        gap: 2.5rem;
    }

    .about-who-we-are-svg svg{
        order: 0;
        display: block;
        margin: 0;
        padding: 0;
        width: 100%;
        height: auto;
        box-sizing: border-box;

    }

    .who-we-are-text {
        width: 100%;
    }

    .about-who-we-are-text {
        order: 1; 
    }

    .who-we-are-title {
        font-size: 1.125rem;
        margin-bottom: 1.5rem;
    }

    .who-we-are-text {
        font-size: 1rem;
    }

}