.about-explore-section {
    padding: 6.25rem 3.75rem;
    padding-bottom: 15rem;
}

.about-explore {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 1.25rem;
    padding: 2rem 3.75rem;
    background-color: var(--grey-800);
}

.about-explore-title {
    font-size: 1.125rem;
    color: var(--blue-300);
}

@media (max-width: 768px) {

    .about-explore-section {
        padding: 3.75rem 1.5rem;
        padding-top: 2.25rem;
        flex-wrap: wrap;
    }

    .about-explore {
        flex-direction: column;
        justify-content: start;
        align-items: flex-start;
        gap: 2.5rem;
        padding: 1.5rem;
        
    }

}


