.tech-partners {
    display: flex;
    border-radius: 20px;
    padding: 32px 24px;
    justify-content: space-between;
    align-items: center;
    background-color: var(--grey-800);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 40px;
    margin-right: 40px;
    max-width: 100%;
    box-sizing: border-box; 
    overflow: hidden; 
}

.tech-title {
    font-size: 18px;
    color: var(--blue-300);
    font-weight: 400;
    line-height: 23.4px;
}

.partners {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 82px;
    margin-right: 80px;
    flex-wrap: wrap; 
    max-width: 100%; 
}

.partner-row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-wrap: nowrap; 
    overflow: hidden; 
}

.separator {
    width: 1px;
    height: 40px;
    background-color: var(--blue-500);
    opacity: 1;
    margin: 0;
}

@media (max-width: 768px) {
    .tech-partners {
        flex-direction: column; 
        align-items: flex-start;
        gap: 16px; 
        margin: 60px 24px;
        overflow: hidden; 
    }

    .partners {
        flex-direction: column; 
        gap: 12px;
        align-items: flex-start;
        padding: 10px; 
        overflow: hidden; 
    }

    .partner-row {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        overflow: hidden; 
    }

    .separator {
        height: 30px;
    }

    .tech-title {
        margin-left: 10px;
        overflow: hidden; 
        text-overflow: ellipsis; 
        white-space: nowrap; 
    }
}
