.body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 20px;
}

h1 {
    font-size: 24px;
}

h2 {
    font-size: 20px;
}

h3 {
    font-size: 18px;
}

h4 {
    font-size: 16px;
}

p {
    margin: 10px 0;
}

ul,
ol {
    margin: 10px 0 10px 20px;
}

.underline {
    text-decoration: underline;
}

.underline {
    text-decoration: underline;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

table,
th,
td {
    border: 1px solid var(--grey-300);
}

th,
td {
    padding: 10px;
    text-align: left;
}