@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://cdn.jsdelivr.net/npm/geist@1.3.1/dist/font.min.css');

:root {
  --navy-400: #3C6173;
  --navy-500: #1D3B49;
  --navy-600: #122027;
  --navy-650: #142730;
  --navy-800: #0E181D;
  --blue-100: #4999C0;
  --blue-300: #5588A1;
  --blue-500: #286684;
  --copy-right: #286684;
  --blue-550: #0C4D6C;
  --blue-700: #304C5B;
  --grey-100: #F0F0F0;
  --grey-300: #D3D4D9;
  --grey-400: #B5C3CA;
  --grey-600: #93A9B3;
  --grey-650: #718E9C;
  --grey-660: #718E9C;
  --grey-670: #286684;
  --button-color: #D3D4D9;
  --grey-800: #15232D;
  --text-color: #333;
  --error: #8E3232;
  --sp-000: #FFFFFF;
  --sp-100: #E5E5E5;
  --border: transparent;
  --description: #D3D4D9;
  --border-color: #5588A1;
  --burger-menu: #286684;
  --project-hover: #142730;
  --days-number: #4999C0;
  --project-card: #122027;

                
                

                
               
                
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-color: var(--navy-800);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.global-button {
  display: flex; 
  justify-content: center; 
  align-items: center; 
  align-content: center;
  font-weight: 500;
  padding: 16px;
  gap: 16px;
  height: 45px;
  width: auto;
  color: var(--button-color);
  font-size: 16px; 
  font-weight: 500; 
  line-height: 23.4px;
  border-radius: 6px; 
  background-color: var(--blue-500); 
  border: none; 
  font-family: inherit; 
  cursor: pointer; 
  transition: opacity 0.3s ease, background-color 0.3s ease; 
}

.global-button:hover {
  background-color: var(--blue-550);
}

.global-button-coming-soon {
  display: flex; 
  justify-content: center; 
  align-items: center; 
  align-content: center;
  font-weight: 500;
  padding: 16px;
  gap: 16px;
  height: 45px;
  width: auto;
  color: var(--button-color);
  font-size: 18px; 
  font-weight: 500; 
  line-height: 23.4px;
  border-radius: 6px; 
  background-color: var(--blue-500); 
  border: none; 
  font-family: inherit; 
  cursor: pointer; 
  transition: opacity 0.3s ease, background-color 0.3s ease; 
}

.global-button-coming-soon:hover {
  background-color: var(--blue-550);
}
 
.about-us-image {
  width: 100%;
  height: 32rem;  
  background-image: url('./assets/svg/about-us.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@media (max-width: 1024px) {
  .about-us-image {
    height: 25rem;  
  }

  .global-button {
    font-size: 14px; 
    width: auto;
  }

  .global-button-coming-soon {
    font-size: 14px; 
    width: auto;
  }
}

@media (max-width: 768px) {
  .about-us-image {
    height: 25vh;  
  }
  .global-button {
    font-size: 14px; 
    width: auto;
  }

  .global-button-coming-soon {
    font-size: 14px; 
    width: auto;
  }
}

@media (max-width: 480px) {
  .about-us-image {
    height: 28vh; 
  }
}

