.selected-projects-section {
    margin: 140px 40px;
    margin-bottom: 100px;
    color: var(--grey-300);
}



.selected-projects-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}


.marketplace {
    font-size: 1.125rem;
    font-weight: 400;
    margin-bottom: 2rem;
}


.selected-projects-title {
    font-size: 44px;
    font-weight: 400;
    margin-bottom: 40px;
}

.filters {
    display: flex;
    gap: 16px;
    flex-wrap: nowrap;
    margin-bottom: 40px;
}


.filters {
    margin-top: 24px;
    margin-bottom: 24px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: start;
    -ms-flex-pack: start;
    justify-content: start;
}

.filter-button {
    border-radius: 100px;
    cursor: pointer;
    height: 35px;
    padding: 12px 32px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.8px;
    background-color: var(--grey-670);
}



.filter-button.active {
    background-color: var(--grey-660);
    color: white;
}



.projects-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    gap: 16px;
    flex-wrap: wrap;
}


.project-card {
    padding: 16px;
    background-color: var(--project-card);
    border-radius: 10px;
    width: 100%;
    max-width: 400px;
    height: 800px;
    border: 1px solid var(--border);
    cursor: pointer;
    height: 100%;
    overflow-y: auto;
}


.project-card:hover {
    background-color: var(--project-hover);
}


.project-card:first-child {
    margin-left: 11px;
}


.project-card::-webkit-scrollbar {
    width: 6px;
}

.project-card::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 3px;
}

.project-card::-webkit-scrollbar-thumb:hover {
    background: #aaa;
}

.project-image {
    width: 100%;
    height: 16/9;
    object-fit: cover;
    margin-bottom: 24px;
    border-radius: 6px;
}

.project-title {
    display: -webkit-box;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 16px;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
    max-height: 72px;
    word-break: break-word;
}

.project-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: left;
    margin-bottom: 16px;
}

.project-description {
    font-size: 14px;
    padding-top: 11px;
    line-height: 18px;
    font-weight: 400;
    height: 63px;
    overflow: hidden;
    color: var(--description);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}



.border {
    border: solid 0.5px var(--border-color);
}

.total-size {
    display: flex;
    justify-content: space-between;
    font-size: 14px;

}


.total-target {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: bold;
    text-align: right;
}


.total-size-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 18.2px;
    margin: 16px 0;
}


.target {
    font-size: 14px;
    font-weight: 600;
    line-height: 18.2px;
    margin: 16px 0;
}


.region {
    font-size: 12px;
}

.project-funded {
    margin: 24px 0;
}



.funding-progress {
    width: 100%;
    height: 10px;
    appearance: none;
    -webkit-appearance: none;
}


.funding-progress::-webkit-progress-bar {
    background-color: var(--navy-800);
    border-radius: 5px;
}


.funding-progress::-webkit-progress-value {
    background-color: var(--blue-500);
    border-radius: 5px;
}

.funding-details {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 400;
    line-height: 18.2px;
    margin-top: 16px;
    color: var(--grey-300);
}

.days-number {
    color: var(--days-number);
    font-weight: bold;
}

.issuer-info {
    margin: 24px 0;
    display: flex;
    justify-content: start;
    align-items: flex-start;
    gap: 16px;
}

.issuer-name-circle {
    width: 43px;
    height: 43px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--blue-700);
    color: #D3D4D9;
    font-weight: bold;
    font-size: 18px;
    font-weight: 500;
    line-height: 23.4px;

}


.issuer-name-type {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 5px;
}

.issuer-type {
    font-size: 10px;
    font-weight: 400;
    line-height: 13px;
    text-align: left;
}


.issuer-name {
    font-size: 14px;
    font-weight: 600;
    line-height: 18.2px;
    text-align: left;
    color: var(--days-number);
    font-weight: bold;
    height: 34px;
}


.issuer-info-line {
    border-top: 1px solid var(--border-color);
    height: 2px;
    margin-bottom: 20px;
}


.selected-projects-buttons {
    display: flex;
    justify-content: start;
    align-items: flex-start;
    gap: 8px;
    flex-wrap: wrap;
    margin-top: 24px;
    margin-bottom: 24px;
    height: 80px;
}


.more-buttons {
    position: relative;
}

.more-buttons .dropdown {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: var(--project-card);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 8px;
    width: 300px;
    flex-wrap: wrap;
    max-height: none;
    overflow: visible;
    z-index: 9999;
}


.more-buttons:hover .dropdown {
    display: flex;
    gap: 5px;
    z-index: 99999;
}

.dropdown button {
    display: flex;
    width: fit-content;
}

.detail-button {
    background-color: var(--navy-500);
    cursor: pointer;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    min-width: 52px;
    width: fit-content;
    height: 29px;
}


.detail-button:hover {
    background-color: var(--navy-400);
    color: var(--grey-400);
}





.projects-carousel {
    width: 100%;
    position: relative;
    /* z-index: 1; */


    .slick-slide {
        display: flex;
        justify-content: center;
        align-items: stretch;
        padding-right: 16px;
        z-index: 1;

    }

}

.mobile-project-carousel {
    width: 100%;

    .slick-slide {
        display: flex;
        justify-content: flex-start;
        align-items: start;
        padding-right: 16px;
    }

    .slick-dots {
        bottom: -20px;
    }
}

.project-region {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 16px;
    margin-bottom: 16px;
    font-size: 12px;
    font-weight: 500;
    line-height: 15.6px;
    gap: 5px;
}

.project-percentage {
    color: var(--blue-100);
}

.project-interest {
    color: var(--grey-300);
}

@media (max-width: 768px) {
    .selected-projects-section {
        margin: 60px 24px;
    }

    .selected-projects-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .marketplace {
        font-size: 14px;
        font-weight: 400;
        line-height: 18.2px;
        margin-bottom: 20px;
    }

    .selected-projects-title {
        font-size: 28px;
        font-weight: 400;
        margin-bottom: 32px;
    }

    .filters {
        display: flex;
        gap: 16px;
        flex-wrap: wrap;
        margin-bottom: 40px;
    }


    .filter-button {
        border-radius: 100px;
        cursor: pointer;
        height: 35px;
        padding: 16px;
        font-size: 16px;
        font-weight: 400;
        line-height: 20.8px;
    }

     .project-card {
        height: 700px;
        overflow-y: auto;
        padding-right: 10px;
    }

    .project-card:has(> *:last-child) {
        overflow-y: auto;
    }

    .project-card::-webkit-scrollbar {
        width: 6px;
    }

    .project-card::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius: 3px;
    }

    .project-card::-webkit-scrollbar-thumb:hover {
        background: var(--grey-300);
    }
}
