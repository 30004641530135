.marketplace-benefits-page {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
}

.marketplace-container {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin: 0 2.5rem;
    gap: 1.5rem;
    margin-bottom: 2.5rem;
}

.marketplace-about {
    margin: 0 2.5rem;
    gap: 1.5rem;
    margin-bottom: 8.75rem;
}

.marketplace-section {
    margin-bottom: 6rem;
}

.marketplace-benefits-section {
    background-image: url(../../assets/svg/background.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 1.5rem;
    padding-bottom: 2.375rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 3rem;
    color: var(--grey-300);
    font-family: 'Geist', sans-serif;
    border-radius: 0.625rem;
    width: 25%;

}

.marketplace-title {
    font-size: 1.5rem;
    font-weight: 500;
    text-align: left;
    color: var(--blue-100);
    height: 30%;

}

.marketplace-text {
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    color: var(--grey-300);
    height: 30%;
}

.marketplace-icon {
    height: 30%;
}

@media (max-width: 1121px) and (min-width: 769px) {

    .marketplace-container {
        align-items: flex-start;
    }

    .marketplace-benefits-section {
        min-height: 600px;

    }

    .marketplace-title {
        height: 120px;
    }

    .marketplace-icon {
        height: 120px;
        margin-top: 0;
        padding-top: 0;
    }

    .marketplace-text {
        height: 200px;
    
    }
}


@media (max-width: 768px) {

    .marketplace-benefits-section {
        background-image: url(../../assets/svg/background.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 1.5rem;
        padding-bottom: 2.375rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 3rem;
        color: var(--grey-300);
        font-family: 'Geist', sans-serif;
        border-radius: 0.625rem;
        width: 100%;
    }

    .marketplace-benefits-page {
        margin-bottom: 2.75rem;
    }

    .marketplace-container {
        flex-direction: column;
        margin: 0 1.5rem;
        margin-bottom: 1.75rem;
        gap: 1rem;
    }

    .marketplace-about {
        margin: 0 1.5rem;
        margin-bottom: 2.75rem;
        gap: 1rem;
    }

    .marketplace-section {
        margin-bottom: 1.75rem;
    }
}