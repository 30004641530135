.body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 20px;
}

h1 {
    font-size: 24px;
}
h2 {
    font-size: 20px;
}
h3 {
    font-size: 18px;
}
h4 {
    font-size: 16px;
}
p {
    margin: 10px 0;
}
ul, ol {
    margin: 10px 0 10px 20px;
}
.underline {
    text-decoration: underline;
}


ol {
    counter-reset: list;
  }
  
  ol > li {
    display: block;
  }
  
  ol > li:before {
    content: counter(list, lower-alpha) ". ";
    counter-increment: list;
  }
  
  ol ol {
    counter-reset: sublist;
  }
  
  ol ol > li:before {
    content: counter(sublist, lower-roman) ". ";
    counter-increment: sublist;
  }
  

.terms-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--grey-300);
    margin-top: 7.5rem;
    margin-left: 25%;
    margin-right: 25%;
}

.terms-title {
    font-size: 40px;
    font-weight: 400;
    line-height: 44px;
    text-align: left;
    margin-bottom: 3.75rem;
}

.terms-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin-bottom: 3.75rem;

}


@media (max-width: 768px) {
    .terms-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: var(--grey-300);
        margin-top: 2.5rem;
        margin-left: 5%;
        margin-right: 5%;
    }

    .terms-title {
        font-size: 30px;
        font-weight: 400;
        line-height: 44px;
        text-align: left;
        margin-bottom: 3.75rem;
    }
}