.main-users {
    margin: 140px 40px;
    font-family: 'Geist', sans-serif;
    color: var(--grey-300);
}

.main-users-content {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.main-users-section {
    display: flex;
    border-radius: 24px;
    padding: 40px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-image: url(../../assets/svg/background.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    gap: 20px;
}

img {
    display: flex;
}

.main-users-texts {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
}

.main-users-texts {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
}

.main-users-texts-second {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    flex: 0 1 50%;

}

.start {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 91px;
}

.main-title {
    color: var(--blue-100);
    font-weight: 600px;
    font-size: 22px;
    line-height: 28.6px;
    margin-bottom: 40px;
    text-align: start;
}

.subtitle {
    color: var(--grey-300);
    font-size: 24px;
    font-weight: 400px;
    line-height: 31.2px;
    margin-bottom: 40px;
    text-align: start;
}

.SpImage-container {
    flex: 0 1 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.ForInvestor-container {
    display: flex;
    align-items: center;
    flex: 1;
}


@media (max-width: 1024px) and (min-width: 769px) {
    .main-users {
        margin: 130px 34px;
        font-family: 'Geist', sans-serif;
        color: var(--grey-300);
    }

    .main-users-section {
        display: flex;
        flex-direction: row; 
        justify-content: space-between; 
        align-items: center; 
        width: 100%;
        padding: 20px;
        gap: 20px;
    }

    .main-users-texts {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        flex: 1;
    }

    .SpImage-container {
        flex: 0 1 50%; 
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 50%; 
    }

    .ForInvestor-container {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .main-title {
        color: var(--blue-100);
        font-weight: 600;
        font-size: 18px;
        line-height: 23.4px;
        margin-bottom: 24px;
        text-align: start;
    }

    .subtitle {
        color: var(--grey-300);
        font-size: 16px;
        font-weight: 400;
        line-height: 20.8px;
        margin-bottom: 24px;
        text-align: start;
    }

    .SpImage-container svg,
    .ForInvestor-container svg {
        display: block;
        margin: 0;
        padding: 0;
        width: 100%;
        height: auto;
        box-sizing: border-box;
    }

    .start {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;       
        margin: 0;           
    }
}

@media (max-width: 768px) {
    .main-users {
        margin: 120px 24px;
        margin-bottom: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .main-users-section {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 20px;
        gap: 20px;
    }

    .main-users-content {
        gap: 20px;
    }

    .SpImage-container,
    .ForInvestor-container {
        width: 100%;
        height: 100%;
        margin: 0 !important;
        padding: 0 !important;
    }

    .main-users-texts {
        order: 2;
    }

    .main-title {
        color: var(--blue-100);
        font-weight: 600;
        font-size: 18px;
        line-height: 23.4px;
        margin-bottom: 24px;
        text-align: start;
    }

    .subtitle {
        color: var(--grey-300);
        font-size: 16px;
        font-weight: 400;
        line-height: 20.8px;
        margin-bottom: 40px;
        text-align: start;
    }

    .SpImage-container svg,
    .ForInvestor-container svg {
        display: block;
        margin: 0;
        padding: 0;
        width: 100%;
        height: auto;
        box-sizing: border-box;
    }


    .main-users-texts {
        margin-top: 20px;
    }
    .main-users-texts-second {
        display: flex;
        flex-direction: column;
        justify-content: flex-start; 
        align-items: flex-start;    
        text-align: left;        
        margin: 0;                  
        gap: 16px;                  
    }

    .start {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;           
        margin: 0;              
    }
}


