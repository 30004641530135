.cookies-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--grey-300);
    margin-top: 7.5rem;
    width: 100%;
    text-align: center;
}

.cookies-title {
    font-size: 40px;
    font-weight: 400;
    line-height: 44px;
    text-align: center;
    margin-bottom: 3.75rem;
}

.cookies-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    margin-bottom: 3.75rem;

}