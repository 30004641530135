.marketplace-main-cover {
    background-color: var(--navy-800);
    margin: 7.5rem 2.5rem;
    margin-bottom: 8.75rem;
    font-family: 'Geist', sans-serif;
    color: var(--grey-300);
}

.marketplace-main-section-title {
    color: var(--blue-100);
    font-size: 24px;
    font-weight: 400;
    line-height: 31.2px;
    margin-bottom: 2.5rem;
}

.marketplace-main-section-text {
    font-size: 60px;
    font-weight: 400;
    line-height: 66px;
    margin-bottom: 3.75rem;

}

@media (max-width: 768px) {
    .marketplace-main-cover {
        margin: 2.563rem 1.5rem;
        margin-bottom: 7.5rem;
    }

    .marketplace-main-section-title {
        font-size: 14px;
        font-weight: 400;
        line-height: 18.2px;
        margin-bottom: 1.5rem;
    }

    .marketplace-main-section-text {
        font-size: 32px;
        font-weight: 400;
        line-height: 36px;
        margin-bottom: 1.5rem;
    }

}