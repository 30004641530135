.main-cover {
    background-color: var(--navy-800);
    margin: 120px 40px;
    font-family: 'Geist', sans-serif;
    font-weight: 500;
    color: var(--grey-300);
}

.main-cover-sp {
    background-color: var(--sp-000);
    margin: 0 !important;
    font-family: 'Geist', sans-serif;
    font-weight: 500;
    color: var(--sp-000);
    padding: 140px 40px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.sub-menu {
    background-color: var(--accent-color);
    border-radius: 16px;
    padding: 16px 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 75%;
    margin-top: -5%;
    margin-left: auto;
    margin-right: 40px;
}

.sub-menu-first {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-right: 1px solid var(--sp-100);
    padding: 10px 60px;
}

.btn-sp {
    background-color:  var(--accent-color);
}
.currency {
    font-weight: 500;
    font-size: 16px;
    line-height: 40px;

}

.sub-menu-first:last-child {
    border: none;
}

.sub-menu-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 18.2px;
    color: var(--sp-000);
    margin-bottom: 16px;
}

.sub-menu-number {
    font-weight: 500;
    font-size: 28px;
    color: var(--sp-000);
    line-height: 40px;
}

.cover-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.title {
    font-weight: 400;
    font-size: 60px;
    line-height: 66px;
    text-align: start;
}

.small-title {
    font-weight: 400;
    font-size: 24px;
    line-height: 31.2px;
    text-align: start;
    margin-bottom: 60px;
}

.button-section {
    display: flex;
    justify-content: flex-start;
    gap: 24px;
}

.watch-video-link {
    display: inline-flex;
    align-items: center;
    gap: 16px;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.3;
    color: var(--grey-300);
}

.watch-video-link span {
    display: inline-block;
}

.watch-video-link svg {
    width: 6.59px;
    height: 11.17px;
    display: inline-block;
}

.icon-stroke path {
    stroke: var(--grey-300);
}

.icon-stroke {
    position: static;
    margin-top: 1px;
}

.btn {
    font-weight: 500;
    width: 140px;
}

.video-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.popup-content {
    position: relative;
    width: 80%;
    max-width: 700px;
    background: white;
    border-radius: 10px;
    overflow: visible;
    padding: 0;
}

.close-button {
    position: absolute;
    top: -20px;
    right: -20px;
    background: var(--navy-600);
    border: none;
    border-radius: 50%;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    z-index: 10;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.close-button svg {
    width: 24px;
    height: 24px;
    color: var(--grey-300);
    transition: transform 0.2s ease, color 0.2s ease;
}

.close-button:hover svg {
    transform: scale(1.1);
    color: var(--grey-300);
}

.video-container iframe {
    width: 100%;
    height: 400px;
    border: none;
}

@media (max-width: 768px) {
    .main-cover {
        margin: 41px 24px;
    }

    .watch-video-link {
        text-decoration: underline;
    }

    .title {
        font-weight: 400;
        font-size: 32px;
        line-height: 36px;
        text-align: start;
    }

    .small-title {
        font-weight: 400;
        font-size: 18px;
        line-height: 23.4px;
        text-align: start;
        margin-bottom: 60px;
    }

    .watch-video-link svg {
        display: none;
    }

    .btn-sp {
        height: 11px;
        color: white !important;
    }

    .close-button {
        width: 30px;
        height: 30px;
        top: -10px;
        right: -10px;
    }

    .close-button svg {
        width: 16px;
        height: 16px;
    }

    .sub-menu {
        background-color: var(--accent-color);
        border-radius: 16px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 95%;
        margin-top: -10%;
        margin-left: 10px;
        margin-right: 10px;
    }

    .sub-menu-first {
        /* display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center; */
        border-bottom: 1px solid var(--sp-100);
        border-right: none;
        padding: 10px;
        width: 100%;
    }

    .default-background {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1; /* Send it behind other content */
        background-size: cover;
        background-position: center;
      }
      

    /* .sub-menu-text {
        font-weight: 500;
        font-size: 14px;
        line-height: 18.2px;
        color: var(--sp-000);
        margin-bottom: 0;
        width: 100%;
    } */
}
