.user-types {
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 60px;
}

.user-types-mobile-container {
    display: flex;
    flex-direction: column;
}

.user-types-cards {
    display: flex;
    justify-content: space-between; 
    gap: 24px;
    max-width: 100%; 
    flex-wrap: wrap; 
}

.card {
    flex: 1; 
    max-width: calc(50% - 1px); 
    min-width: 175px; 
    box-sizing: border-box; 
    display: flex;
    flex-direction: column;
    align-items: center;
}


.card-title {
    text-align: start;
    font-size: 18px;
    font-weight: 400;
    line-height: 23.4px;
    margin-bottom: 24px;
    align-self: start;
    color: var(--grey-300);
}


.card-line {
    border-top: 1px solid var(--blue-300);
    width: 100%;
    height: 2px;
    margin-bottom: 24px;
}

.card-content {
    width: 100%;
    border-radius: 10px;
    background-image: url(../../assets/svg/background.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    height: 500px;
    display: flex;
    justify-content: space-evenly;
}


.card-content-text {
    display: flex;
    justify-content: center;
    align-items: start;
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    color: var(--grey-300);
    text-align: start;
    width: 100%;
    margin-bottom: 24px;
    height: 130px;
}

.type-button {
    font-family: 'Geist', sans-serif;
    font-weight: 500;
    width: 100%;
    color: var(--grey-300);
}

.card-image {
    padding: 21.5px 80px;
    height: 204px;
}

.p-2\.5 {
    padding: 0.999rem;
}

.arrow-button {
    display: none;
}

@media (max-width: 1232px) {
    .type-button {
        font-size: 12px;
    }
}


@media (max-width: 1024px) {
    body {
        overflow-x: hidden;
    }

    .card-content-text {
        align-items: start;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        margin-bottom: 0;
        height: 110px;
    }

    .type-button {
        display: none;
    }

    .arrow-button {
        display: inline-flex;
        align-self: flex-start;
    }

    .card {
        min-width: 164px;
    }

    .card-content {
        height: 100%;
        align-items: flex-start;
        gap: 16px;
        padding: 20px 16px;
    }

    .user-types {
        margin-left: 24px;
        margin-right: 24px;
    }

    .user-types-cards {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 16px;
        max-width: 100%;
        flex-wrap: wrap; 
    }

    .card-image {
        padding: 0;
        margin: 16px 0;
    }

    .card-image svg {
        width: 100px;
        height: 100px;
    }
}


@media (max-width: 390px) {
    .card {
        min-width: 100px;
    }
}



