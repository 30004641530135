.contact-support-modal {
    background-color: rgba(21, 70, 94, 0.8);
    backdrop-filter: blur(4px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    overflow-y: auto;
    font-family: 'Geist', sans-serif;
}


/* .contact-support-form {
    background-color: var(--navy-600);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 1.25rem;
    padding: 2rem;
    box-shadow: 0px 4px 16px 0px #00000066;
    position: relative;
    width: 100%;
} */

.contact-support-form {
    background-color: var(--navy-600);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3.375rem;
    border-radius: 1.25rem;
    padding: 2rem;
    width: 100%;
    margin-left: 25%;
    margin-right: 25%;
    box-shadow: 0px 4px 16px 0px #00000066;
}

.contact-support-title-section-parent {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: center;
    justify-content: space-between;
    font-size: 2rem;
    font-weight: 400;
    text-align: left;
    width: 100%;
    color: var(--grey-300);

}

.contact-support-title-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 2rem;
    font-weight: 400;
    text-align: left;
    width: 100%;
    color: var(--grey-300);
}

.contact-italic {
    font-size: 1rem;
    font-style: italic;
    align-self: flex-start;
}

.success-message {
    margin-top: -40px;
    color: var(--grey-300);
    align-self: flex-start;
}


.contact-us-close-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    align-self: flex-end;
}

.contact-us-close-icon svg {
    width: 1.25rem;
    height: 1.25rem;
    fill: var(--grey-300);
}

.contact-us-close-icon:hover {
    background-color: var(--grey-700);
    border-radius: 50%;
}


.contact-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
}

.form-row {
    display: flex;
    gap: 1.25rem;
}

.form-input {
    flex: 1;
    background-color: transparent;
    color: var(--grey-300);
    border: 1px solid var(--navy-500);
    border-radius: 0.25rem;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    outline: none;
    width: 100%;
}

.form-textarea {
    flex: 1;
    background-color: transparent;
    color: var(--grey-300);
    border: 1px solid var(--navy-500);
    border-radius: 0.25rem;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    outline: none;
    width: 100%;
}

.form-input.full-width {
    width: 100%;
}

.full-width {
    width: 100%;
}

.form-input.half-width {
    width: 49%;
}

.form-input:hover {
    border-color: var(--navy-400);
}


.form-textarea:hover {
    border-color: var(--navy-400);
}

.form-input:focus {
    border-color: var(--grey-300);
}

.form-textarea:focus {
    border-color: var(--grey-300);
}

.send-message-button {
    width: 26%;
    margin-top: 1.875rem;
}


.error {
    border-color: var(--error);
}

.error-message {
    color: var(--error);
    font-size: 0.875rem;
    text-align: center;
    margin-bottom: 1rem;
}

.form-field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    color: var(--error);
    width: 50%;
}



.textarea-field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    color: var(--error);
    width: 100%;
}


.form-control {
    background-color: var(--navy-600) !important;
    color: var(--grey-300);
    border-color: var(--navy-500) !important;
    flex: 1 !important;
    width: 100% !important;
    height: 100% !important;
    padding-bottom: 0.96rem !important; 
    padding-top: 0.96rem !important;
}

.react-tel-input {
    border-color: var(--navy-500) !important;
    height: 50px;
}


.flag-dropdown {
    background-color: var(--navy-600) !important;
    border-color: var(--navy-500) !important;
}

.phone-input-container.error {
    border: 1px solid var(--error) !important;
    border-radius: 0.25rem;
}

.phone-input-container:hover {
    border: 1px solid var(--navy-400) !important;
    border-radius: 0.25rem;
}

.phone-input-container .form-input.error {
    border: none;
    outline: none;
}

.react-tel-input .flag-dropdown {
    background-color: var(--navy-600) !important;
    border-color: var(--navy-500) !important;
}



.form-field.error-container {
    margin-bottom: 1rem; 
}



@media (max-width: 768px) {

    .contact-support-modal {
        background-color: rgba(21, 70, 94, 0.8);
        backdrop-filter: blur(4px);
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
        font-family: 'Geist', sans-serif;
        overflow-y: auto;
        padding-top: 1rem;
        padding-bottom: 1rem;
        overflow-x: hidden;
    }

    .contact-support-form {
        gap: 2rem;
        margin-left: 5%;
        margin-right: 5%;
    }

    /* .contact-support-form {
        gap: 2rem;
        padding: 1rem;
        margin-left: 10%;
        margin-right: 10%;
    } */

    .contact-support-title-section {
        font-size: 1.25rem;
    }

    .contact-us-close-icon {
        width: 1.25rem;
        height: 1.25rem;
        margin-bottom: 16px;
    }

    .form-row {
        flex-direction: column;
    }

    .form-field {
        width: 100%;
    }

    .form-input.half-width {
        width: 100%;
    }

    .send-message-button {
        margin-top: 0.5rem;
        width: 120px !important
    }

    .success-message {
        margin-top: -20px;
    }
 
    .global-button {
        font-size: 14px;
    }
}