.testimonials-section {
    margin: 140px 40px;
    color: var(--grey-300);
    margin-top: 0;

}


.testimonial-section-title {
    font-size: 24px;
    font-weight: 400;
    line-height: 31.2px;
    margin-bottom: 40px;
    text-align: left;
}

.testimonials-row {
    display: flex;
    justify-content: space-between;
    gap: 24px;
}

.testimonial-item {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}


.testimonial-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.testimonial-info {
    display: flex;
    flex-direction: column;
    align-items: last baselin;
    position: absolute;
    bottom: 10px;
    margin-left: 40px;
    color: var(--grey-300);
    border-radius: 5px;
}

.testimonial-title {
    font-size: 30px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;
    margin-bottom: 24px;
    width: 350px;
}

.testimonial-name,
.testimonial-company {
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    margin-bottom: 20px;


}


.testimonials-carousel {
    width: 100%;

    .slick-slide {
        display: flex;
        justify-content: flex-start;
        align-items: start;
        padding: 0 10px;
    }

    .slick-dots {
        bottom: -20px;
    }
}


@media (max-width: 768px) {

    .testimonials-section {
        margin: 60px 24px;
    }

    .testimonial-item {
        margin: 0 10px;
    }

    .testimonial-info {
        margin-left: 16px;
    }

    .testimonials-row {
        gap: 16px;
    }

    .testimonial-section-title {
        font-family: Geist;
        font-size: 14px;
        font-weight: 400;
        line-height: 18.2px;
        text-align: left;
        margin-bottom: 32px;

    }

    .testimonial-title {
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
        margin: 0;
    }

    .testimonial-name,
    .testimonial-company {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 14px;
        margin-top: 20px;


    }



}