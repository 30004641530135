.about-main-cover {
    background-color: var(--navy-800);
    margin: 7.5rem 5rem;
    font-family: 'Geist', sans-serif;
    color: var(--grey-300);
}

.about-title {
    color: var(--blue-100);
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 40px;
}

.cover-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.about-text {
    font-weight: 400;
    font-size: 60px;
    line-height: 66px;
    text-align: start;
    margin-bottom: 60px;
    width: 70%;
}

.small-title {
    font-weight: 400;
    font-size: 24px;
    line-height: 31.2px;
    text-align: start;
    margin-bottom: 60px;
}

.button-section {
    display: flex;
    justify-content: flex-start;
    gap: 24px;
}

.watch-video-link {
    display: inline-flex;
    align-items: center;
    gap: 16px;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.3;
    color: var(--grey-300);
}

.watch-video-link span {
    display: inline-block;
}

.watch-video-link svg {
    width: 6.59px;
    height: 11.17px;
    display: inline-block;
}

.icon-stroke path {
    stroke: var(--grey-300);
}

.icon-stroke {
    position: static;
    margin-top: 1px;
}

.btn {
    font-weight: 500;
    width: 160px;
}

.video-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.popup-content {
    position: relative;
    width: 80%;
    max-width: 700px;
    background: white;
    border-radius: 10px;
    overflow: visible;
    padding: 0;
}

.close-button {
    position: absolute;
    top: -20px;
    right: -20px;
    background: var(--navy-600);
    border: none;
    border-radius: 50%;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    z-index: 10;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.close-button svg {
    width: 24px;
    height: 24px;
    color: var(--grey-300);
    transition: transform 0.2s ease, color 0.2s ease;
}

.close-button:hover svg {
    transform: scale(1.1);
    color: var(--grey-300);
}

.video-container iframe {
    width: 100%;
    height: 400px;
    border: none;
}

@media (max-width: 768px) {
    .about-main-cover {
        margin: 2.563rem 1.5rem;
        margin-bottom: 7.5rem;
    }

    .watch-video-link {
        text-decoration: underline;
    }

    .about-title {
        font-weight: 400;
        font-size: 0.875rem;
        text-align: start;
        margin-bottom: 0;
    }

    .about-text {
        font-size: 2rem;
        font-weight: 400;
        line-height: 36px;
        text-align: start;
        width: 100%;
        margin-top: 24px;
        margin-bottom: 0;
    }

    .small-title {
        font-weight: 400;
        font-size: 18px;
        line-height: 23.4px;
        text-align: start;
        margin-bottom: 48px;
        margin-top: 24px;
    }

    .watch-video-link svg {
        display: none;
    }

    .btn {
        height: 11px;
    }

    .close-button {
        width: 30px;
        height: 30px;
        top: -10px;
        right: -10px;
    }

    .close-button svg {
        width: 16px;
        height: 16px;
    }
}