.about-mission-section {
    font-family: 'Geist', sans-serif;
    width: 100%;
    background-image: url(../../assets/svg/background.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 6.25rem 5rem;
    gap: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: var(--grey-300);
}

.our-mission {
    font-size: 1.125rem;
    font-weight: 400;
}

.our-mission-section {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    justify-content: center;
    align-items: flex-start;
}

.our-mission-title-text {
    display: flex;
    flex-direction: column;
    width: 35%;
}

.our-mission-content {
    display: flex;
    gap: 6.5rem;
}

.our-mission-content-title {
    font-size: 1.375rem;
    font-weight: 600;
    text-align: left;
    margin-bottom: 2.5rem;
    color: var(--blue-100);
}

.our-mission-content-text {
    font-size: 1.5rem;
    font-weight: 400;
    text-align: left;
}


@media (max-width: 768px) {
    .about-mission-section {
        padding: 2.5rem 1.5rem;
        gap: 3.75rem;
    }

    .our-mission-section {
        flex-direction: column;
        gap: 0;
    }

    .our-mission-content {
       flex-direction: column;
    }

    .our-mission-section {
        align-items: center;
    }

    .our-mission-content-text {
        font-size: 1.125rem;

    }

    .our-mission-title-text {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 0;
    }

    .our-mission-content {
        gap: 0;
    }

    .unleash {
        margin-bottom: 2.5rem;
    }

    .our-mission-content-title {
        margin-bottom: 1.5rem;    
    }
    

}



